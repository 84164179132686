<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">ลืมรหัสผ่าน</label>
    </div>
    <div class="info-paragraph">
      <label
        class="info-text"
      >ขั้นตอนการรีเซ็ตรหัสผ่าน</label>
      <label
        class="info-text"
      >1. ใส่อีเมลที่ใช้สมัครสมาชิกแล้วกดปุ่ม "รีเซ็ตรหัสผ่าน" จากนั้นรอรับอีเมลจากระบบ</label>
      <label
        class="info-text"
      >2. อ่านขั้นตอนในการตั้งค่ารหัสผ่านใหม่ในอีเมลที่ได้รับ หากไม่พบ ลองดูในถังขยะ</label>
    </div>
    <div>
      <TextField
        :value="email"
        place-holder="อีเมล"
        @setValue="setEmail"
      />
    </div>
    <div style="text-align:center">
      <v-btn
        class="px-12 resetpassword_btn"
        :loading="loading"
        :disabled="loading || !submitAble"
        @click="onSubmit"
      >
        <span>รีเซ็ตรหัสผ่าน</span>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR } from '@/store/_actionTypes';
import TextField from '@/components/TextField.vue';
import { request } from '@/services/resetpassword';

export default {
  name: 'ForgetPassword',
  components: {
    TextField,
  },
  data: () => ({
    loading: false,
    email: ''
  }),
  computed: {
    submitAble() {
      if (this.email.length === 0)
        return false;

      return true;
    }
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR]),
    setEmail(value) {
      this.email = value;
    },
    async onSubmit() {
      try {
        this.loading = true;
        await request(this.email);
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.response.data.error);
        this.loading = false;
        return;
      }

      setTimeout(() => {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('ได้รับคำร้องแล้ว โปรดตรวจสอบอีเมลของท่าน');
        this.loading = false;
        this.email = '';
      }, 1000);
    }
  }
};
</script>
<style scoped lang="scss">
.resetpassword_btn {
  background-color: $primary-color-02 !important;
  font-family: Kanit;
  font-size: 16px;
  color: $white-01;
}

.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > ul {
    margin-top: 32px;
}

.info-paragraph > ul > li {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    margin-left: 32px;
}

</style>